<script>
import MainContainer from '@/components/main/MainContainer.vue'
import MainLogo from '@/components/main/MainLogo.vue'

export default {
	name: 'MainFooter',
	components: {
		MainContainer,
		MainLogo,
	},
	data() {
		return {
			links: [
				{
					to: '/#about',
					name: 'site.menu.about',
				},
				{
					to: '/#map',
					name: 'site.menu.map',
				},
				{
					to: '/#news',
					name: 'site.menu.news',
				},
				{
					to: '/#video',
					name: 'site.menu.video',
				},
				{
					to: '/#faq',
					name: 'site.menu.faq',
				},
				{
					to: `/${this.$i18n.locale}/contact`,
					name: 'site.menu.contact',
				},
				{
					to: `/${this.$i18n.locale}/sitemap`,
					name: 'site.menu.sitemap',
				},
			],
		}
	},
}
</script>

<template>
	<footer class="relative py-[40px]">
		<MainContainer>
			<div
				class="flex flex-col justify-between items-center gap-2 gap-y-[40px] mb-[50.63px] md:flex-row"
			>
				<MainLogo />

				<div
					class="w-full max-w-[810px] flex flex-wrap flex-col items-center justify-between gap-2 md:flex-row"
				>
					<router-link
						v-for="link in links"
						:key="link.to"
						:to="link.to"
						class="font-main font-light text-[13px] text-second leading-5 hover:no-underline"
					>
						{{ $t(link.name) }}
					</router-link>
				</div>
			</div>

			<div
				class="mb-[46px] flex flex-wrap items-center justify-center gap-x-[30px] gap-y-[21px] md:justify-start"
			>
				<a
					href="https://go.2gis.com/j1RBs"
					target="_blank"
					class="inline-flex items-center gap-[10px] font-main font-light text-[13px] text-second hover:no-underline"
				>
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current !text-[#475F84] stroke-[2px] shrink-0'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.34 7.733c0 1.29-1.042 2.34-2.34 2.34a2.333 2.333 0 01-2.34-2.34c0-1.298 1.05-2.34 2.34-2.34.255 0 .503.037.728.112"/><path d="M4.485 3.225c3.278-3.082 9.63-2.025 10.8 3.158.863 3.81-1.507 7.035-3.585 9.03a3.895 3.895 0 01-5.407 0c-2.07-2.003-4.448-5.228-3.578-9.038"/></svg>
					<span>{{ $t('address') }}</span>
				</a>
				<a
					href="mailto:balyk.komiteti@minagri.gov.kz"
					class="inline-flex items-center gap-[10px] font-main font-light text-[13px] text-second hover:no-underline"
				>
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current !text-[#475F84] stroke-[2px shrink-0'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.5 9.735v1.89c0 2.625-1.5 3.75-3.75 3.75h-7.5M1.5 6.375c0-2.625 1.5-3.75 3.75-3.75h7.5c2.25 0 3.75 1.125 3.75 3.75M12.75 6.75l-2.348 1.875c-.772.615-2.04.615-2.812 0L5.25 6.75M1.5 12.375H6M1.5 9.375h2.25"/></svg>
					<span>balyk.komiteti@minagri.gov.kz</span>
				</a>
				<a
					href="tel:+77172740126"
					class="inline-flex items-center gap-[10px] font-main font-light text-[13px] text-second hover:no-underline"
				>
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current !text-[#475F84] stroke-[2px] shrink-0'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.043 4.508c.135.187.232.36.3.524.067.158.104.316.104.458 0 .18-.052.36-.157.532-.098.173-.24.353-.42.533l-.57.593a.401.401 0 00-.12.3c0 .06.008.112.022.172.023.06.045.105.06.15.136.248.368.57.698.96.338.39.697.787 1.087 1.185.405.398.795.765 1.193 1.103.39.33.713.554.967.69.038.014.083.037.135.06.06.022.12.03.188.03a.413.413 0 00.308-.128l.57-.563c.187-.187.367-.33.54-.42a.999.999 0 01.532-.157c.143 0 .293.03.457.098a2.9 2.9 0 01.526.292l2.482 1.762c.195.136.33.293.413.48.075.188.12.375.12.585 0 .27-.06.548-.188.818-.127.27-.292.525-.51.765a3.381 3.381 0 01-1.23.885c-.45.188-.938.285-1.463.285-.764 0-1.582-.18-2.444-.547a13.173 13.173 0 01-2.58-1.486 21.563 21.563 0 01-2.46-2.1A21.31 21.31 0 013.51 9.915c-.615-.855-1.11-1.71-1.47-2.557-.36-.855-.54-1.673-.54-2.453 0-.51.09-.998.27-1.448.18-.457.465-.877.862-1.252.48-.473 1.006-.705 1.56-.705.21 0 .42.045.608.135.195.09.368.225.503.42M13.875 6.75c0-.45-.352-1.14-.877-1.702-.48-.518-1.118-.923-1.748-.923M12.758 1.718A5.27 5.27 0 0011.25 1.5M16.5 6.75a5.24 5.24 0 00-2.235-4.297"/></svg>
					<span>+7 7172 74-01-26, +7 7172 74-03-29</span>
				</a>
			</div>

			<div>
				<p class="font-main font-light text-[13px] text-[#A2B8DA] m-0 text-center md:!text-left">
					Copyright © 2025 efish. All rights reserved.
				</p>
			</div>
		</MainContainer>
	</footer>
</template>

<style></style>
