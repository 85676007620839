<script>
import { mapGetters } from 'vuex'

import { api } from '@/boot/axios'

export default {
	name: 'MainAccountBtn',
	computed: {
		...mapGetters(['_getUserObject']),
	},
	beforeMount() {
		this.apiGetUser()
	},
	methods: {
		mobileActive(status) {
			this.mobileStatus = status
		},
		apiGetUser() {
			const token = localStorage.getItem('token')

			if (!token) return

			api
				.get('user')
				.then((response) => {
					if (response.data) {
						if (response.data.role.accesses) {
							this.saveData(response.data)
						}
					}
				})
				.catch(() => {})
		},
		saveData(data) {
			this.$store.commit('SET_USER_OBJECT', data)
			this.$store.commit('getUserRoles', data.role.accesses)
			localStorage.setItem('USER_INFO_LOAD_TIME', new Date().getTime())
		},
	},
}
</script>

<template>
	<div>
		<router-link
			v-if="_getUserObject"
			:to="'/' + $i18n.locale + '/account'"
			class="flex justify-center items-center gap-x-[10px] p-[10px] rounded-[90px] bg-bgOverlay font-main font-normal text-[16px] text-second no-underline hover:no-underline hover:text-second"
		>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current text-main stroke-[2px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.94 8.04c.22.44.35.94.35 1.47-.01 1.77-1.4 3.22-3.16 3.27a.963.963 0 00-.24 0 3.269 3.269 0 01-3.16-3.27c0-1.81 1.46-3.28 3.28-3.28M18.74 19.38A9.934 9.934 0 0112 22c-2.6 0-4.96-.99-6.74-2.62.1-.94.7-1.86 1.77-2.58 2.74-1.82 7.22-1.82 9.94 0 1.07.72 1.67 1.64 1.77 2.58z"/><path d="M4 6c-1.25 1.67-2 3.75-2 6 0 5.52 4.48 10 10 10s10-4.48 10-10S17.52 2 12 2c-1.43 0-2.8.3-4.03.85"/></svg>
			<span>{{ `${_getUserObject.last_name} ${_getUserObject.first_name[0]}.` }}</span>
			<span> | </span>
			<span class="font-light text-[12px]">{{ _getUserObject.role.description }}</span>
		</router-link>
		<router-link
			v-else
			:to="'/' + $i18n.locale + '/login'"
			class="w-full inline-flex justify-center items-center gap-x-[10px] p-[10px] rounded-[90px] bg-bgOverlay font-main font-normal text-[16px] text-second no-underline hover:no-underline hover:text-second"
		>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current text-second stroke-[2px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.94 8.04c.22.44.35.94.35 1.47-.01 1.77-1.4 3.22-3.16 3.27a.963.963 0 00-.24 0 3.269 3.269 0 01-3.16-3.27c0-1.81 1.46-3.28 3.28-3.28M18.74 19.38A9.934 9.934 0 0112 22c-2.6 0-4.96-.99-6.74-2.62.1-.94.7-1.86 1.77-2.58 2.74-1.82 7.22-1.82 9.94 0 1.07.72 1.67 1.64 1.77 2.58z"/><path d="M4 6c-1.25 1.67-2 3.75-2 6 0 5.52 4.48 10 10 10s10-4.48 10-10S17.52 2 12 2c-1.43 0-2.8.3-4.03.85"/></svg>
			<span>{{ $t('commands.enter') }}</span>
		</router-link>
	</div>
</template>

<style></style>
