<script>
import MainContainer from '@/components/main/MainContainer.vue'
import MainNav from '@/components/main/MainNav.vue'
import MainLogo from '@/components/main/MainLogo.vue'
import MainLang from '@/components/main/MainLang.vue'
import MainAccountBtn from '@/components/main/MainAccountBtn.vue'

export default {
	name: 'MainHeader',
	components: {
		MainContainer,
		MainNav,
		MainLogo,
		MainLang,
		MainAccountBtn,
	},
}
</script>

<template>
	<header class="relative z-[1000] py-[30px]">
		<MainContainer class="flex justify-between">
			<div class="flex items-center gap-x-[21px]">
				<MainNav class="hidden lg:block" />
				<MainLogo />
			</div>

			<div class="flex justify-end items-center gap-x-[10px]">
				<MainLang />
				<MainAccountBtn class="hidden lg:block" />
				<MainNav class="block lg:hidden" />
			</div>
		</MainContainer>
		<MainContainer class="flex justify-center lg:hidden">
			<MainAccountBtn class="w-full max-w-[360px] mx-auto mt-[25.63px] flex justify-center" />
		</MainContainer>
	</header>
</template>

<style></style>
