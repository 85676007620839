<template>
	<div class="wrapper wrapper--site">
		<MainHeader />
		<v-mobile-header @mobileActive="mobileActive"></v-mobile-header>

		<router-view />

		<MainFooter />
	</div>
</template>

<script>
import MainHeader from '@/components/main/MainHeader.vue'
import MainFooter from '@/components/main/MainFooter.vue'

export default {
	name: 'IndexPage',
	components: {
		MainHeader,
		MainFooter,
	},
	data() {
		return {
			mobileStatus: false,
		}
	},
	methods: {
		mobileActive(status) {
			this.mobileStatus = status
		},
	},
	watch: {},
}
</script>
