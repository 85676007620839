<script>
export default {
	name: 'MainLang',
	data() {
		return {
			isActive: false,
			codes: {
				ru: 'Рус',
				kz: 'Қаз',
			},
		}
	},
	computed: {
		isActiveLang() {
			return (lang) => {
				return this.$i18n.locale === lang
			}
		},
	},
	methods: {
		selectLang(lang) {
			if (this.$i18n.locale === lang) {
				this.isActive = !this.isActive
				return
			} else {
				this.$i18n.locale = lang
				this.$router.push({
					params: { lang: lang },
				})
				this.isActive = false
			}
		},
	},
}
</script>

<template>
	<div class="relative w-[44px] h-[44px]">
		<div
			:class="{ 'h-[88px]': isActive, 'h-[44px]': !isActive }"
			class="relative rounded-[24.5px] bg-bgOverlay transition-300 overflow-hidden"
		>
			<button
				v-for="code in Object.keys(codes)"
				:key="code"
				:class="{ 'absolute top-[44px]': !isActiveLang(code) }"
				class="flex justify-center items-center border-none cursor-pointer p-0 w-[44px] h-[44px] rounded-full bg-bgOverlay font-main font-normal text-[17px] text-center text-main outline-none focus:outline-none active:outline-none hover:outline-none"
				@click="selectLang(code)"
			>
				{{ codes[code] }}
			</button>
		</div>
	</div>
</template>

<style></style>
