<script>
export default {
	name: 'MainNav',
	data() {
		return {
			isActive: false,
			links: [
				{
					to: '/#about',
					name: 'site.menu.about',
				},
				{
					to: '/#map',
					name: 'site.menu.map',
				},
				{
					to: '/#news',
					name: 'site.menu.news',
				},
				{
					to: '/#video',
					name: 'site.menu.video',
				},
				{
					to: '/#faq',
					name: 'site.menu.faq',
				},
			],
		}
	},
}
</script>

<template>
	<nav>
		<button
			class="flex justify-center items-center border-none bg-bgOverlay p-0 w-[39px] h-[39px] rounded-full cursor-pointer"
			@click="isActive = !isActive"
		>
			<img src="@/assets/svgs/menu.svg" :alt="'efish menu logo'" class="block" />
		</button>
		<Transition name="menu-slide">
			<div
				v-show="isActive"
				class="fixed top-0 bottom-0 right-0 z-[1000] w-full h-screen max-w-[661px] pt-[70px] pl-[30px] pr-[30px] pb-[70px] bg-main shadow-4xl lg:left-0 lg:right-none lg:pl-[70px] lg:pr-[70px]"
			>
				<svg width="661" height="511" viewBox="0 0 661 511" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'absolute bottom-0 left-1/2 transform -translate-x-1/2 z-0 pointer-events-none'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M-86.67 411.955c-71.048 34.365 21.965 172.418 21.965 172.418L-88.576 731.16l898.241 280.03 139.481-706.753-42.731-63.448s-74.232 27.891-113.21 44.851c-38.978 16.96-176.989-79.044-220.955-59.204-43.966 19.839-28.396 53.523-73.275 68.902-44.879 15.379-126.14-77.364-167.192-44.798-41.051 32.565-5.626 99.677-69.905 103.533-64.279 3.857-156.614-115.745-218.357-37.428-61.743 78.317-59.143 60.744-130.19 95.11z" fill="url(#paint0_linear_4132_3976)"/><path d="M-20.799 424.432C-82.398 454.246-1.775 573.974-1.775 573.974l-20.711 127.315 778.702 242.783 121.001-613.007-37.039-55.025s-64.359 24.199-98.153 38.913c-33.794 14.714-153.433-68.538-191.551-51.326-38.119 17.212-24.624 46.426-63.534 59.77-38.909 13.343-109.349-67.088-144.942-38.838-35.593 28.25-4.889 86.454-60.616 89.806-55.727 3.352-135.764-100.372-189.301-32.439-53.537 67.933-51.28 52.692-112.88 82.506z" fill="url(#paint1_linear_4132_3976)"/><defs><linearGradient id="paint0_linear_4132_3976" x1="112.577" y1="303.026" x2="712.681" y2="803.959" gradientUnits="userSpaceOnUse"><stop stop-color="#F9FDFF"/><stop offset="1" stop-color="#45B6DD"/></linearGradient><linearGradient id="paint1_linear_4132_3976" x1="186.5" y1="336.5" x2="363.055" y2="505.943" gradientUnits="userSpaceOnUse"><stop stop-color="#B4E4FD"/><stop offset="1" stop-color="#157FEE"/></linearGradient></defs></svg>

				<div class="flex justify-end lg:justify-start">
					<button
						class="border-none bg-transparent p-0 outline-none cursor-pointer mb-[70px]"
						@click="isActive = false"
					>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'block'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 21L21 3M21 21L3 3" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</button>
				</div>

				<ul class="p-0 flex flex-col items-start gap-[20px] mb-[60px]">
					<li v-for="link in links" :key="link.to">
						<router-link
							:to="link.to"
							class="block font-main font-black text-[20px] text-white leading-[1.25] no-underline duration-300 hover:no-underline hover:!text-[#7CBCFF] md:text-[30px] lg:text-[40px]"
						>
							{{ $t(link.name) }}
						</router-link>
					</li>
				</ul>

				<div class="flex flex-col gap-[20px]">
					<a
						href="https://go.2gis.com/j1RBs"
						target="_blank"
						class="inline-flex items-center gap-[10px] font-main font-light text-[13px] text-white hover:no-underline"
					>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current !text-white stroke-[2px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.34 7.733c0 1.29-1.042 2.34-2.34 2.34a2.333 2.333 0 01-2.34-2.34c0-1.298 1.05-2.34 2.34-2.34.255 0 .503.037.728.112"/><path d="M4.485 3.225c3.278-3.082 9.63-2.025 10.8 3.158.863 3.81-1.507 7.035-3.585 9.03a3.895 3.895 0 01-5.407 0c-2.07-2.003-4.448-5.228-3.578-9.038"/></svg>
						<span>{{ $t('address') }}</span>
					</a>
					<a
						href="mailto:balyk.komiteti@minagri.gov.kz"
						class="inline-flex items-center gap-[10px] font-main font-light text-[13px] text-white hover:no-underline"
					>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current !text-white stroke-[2px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.5 9.735v1.89c0 2.625-1.5 3.75-3.75 3.75h-7.5M1.5 6.375c0-2.625 1.5-3.75 3.75-3.75h7.5c2.25 0 3.75 1.125 3.75 3.75M12.75 6.75l-2.348 1.875c-.772.615-2.04.615-2.812 0L5.25 6.75M1.5 12.375H6M1.5 9.375h2.25"/></svg>
						<span>balyk.komiteti@minagri.gov.kz</span>
					</a>
					<a
						href="tel:+77172740126"
						class="inline-flex items-center gap-[10px] font-main font-light text-[13px] text-white hover:no-underline"
					>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current !text-white stroke-[2px]'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.043 4.508c.135.187.232.36.3.524.067.158.104.316.104.458 0 .18-.052.36-.157.532-.098.173-.24.353-.42.533l-.57.593a.401.401 0 00-.12.3c0 .06.008.112.022.172.023.06.045.105.06.15.136.248.368.57.698.96.338.39.697.787 1.087 1.185.405.398.795.765 1.193 1.103.39.33.713.554.967.69.038.014.083.037.135.06.06.022.12.03.188.03a.413.413 0 00.308-.128l.57-.563c.187-.187.367-.33.54-.42a.999.999 0 01.532-.157c.143 0 .293.03.457.098a2.9 2.9 0 01.526.292l2.482 1.762c.195.136.33.293.413.48.075.188.12.375.12.585 0 .27-.06.548-.188.818-.127.27-.292.525-.51.765a3.381 3.381 0 01-1.23.885c-.45.188-.938.285-1.463.285-.764 0-1.582-.18-2.444-.547a13.173 13.173 0 01-2.58-1.486 21.563 21.563 0 01-2.46-2.1A21.31 21.31 0 013.51 9.915c-.615-.855-1.11-1.71-1.47-2.557-.36-.855-.54-1.673-.54-2.453 0-.51.09-.998.27-1.448.18-.457.465-.877.862-1.252.48-.473 1.006-.705 1.56-.705.21 0 .42.045.608.135.195.09.368.225.503.42M13.875 6.75c0-.45-.352-1.14-.877-1.702-.48-.518-1.118-.923-1.748-.923M12.758 1.718A5.27 5.27 0 0011.25 1.5M16.5 6.75a5.24 5.24 0 00-2.235-4.297"/></svg>
						<span>+7 7172 74-01-26, +7 7172 74-03-29</span>
					</a>
				</div>
			</div>
		</Transition>
	</nav>
</template>

<style>
.menu-slide-enter-active,
.menu-slide-leave-active {
	transition: transform 0.5s ease-in-out;
}
.menu-slide-enter,
.menu-slide-leave-to {
	transform: translateX(100%);
}

@media screen and (min-width: 1024px) {
	.menu-slide-enter,
	.menu-slide-leave-to {
		transform: translateX(-100%);
	}
}
</style>
